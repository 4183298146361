import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import { Route as RouteType } from './RouteTypes';
import AppErrorBoundary from '../../../AppErrorBoundary';
import SuspenseLoader from '../SuspenseLoader';

// The public routes are ALL loaded from separate bundles.
// Every one is lazy loaded

// Keep this list sorted alphabetically to avoid accidental duplication

const publicRoutes: RouteType[] = [
  {
    path: '/about',
    component: React.lazy(() => import('../../../statics/About')),
  },
  {
    path: '/ai/book-description-generator',
    component: React.lazy(() => import('../../../statics/DescriptionAi')),
  },
  {
    path: '/ai/pen-name-generator',
    component: React.lazy(() => import('../../../statics/PenNameAi')),
  },
  {
    path: '/ai/book-character-generator',
    component: React.lazy(() => import('../../../statics/CharacterAi')),
  },
  {
    path: '/ai/book-title-generator',
    component: React.lazy(() => import('../../../statics/TitleAi')),
  },
  {
    path: '/ai/book-plot-generator',
    component: React.lazy(() => import('../../../statics/PlotAi')),
  },
  {
    path: '/author-resources',
    component: React.lazy(
      () => import('../../../statics/help/AuthorResources')
    ),
  },
  {
    path: '/author-resources/running-successful-promotions-any-genre',
    component: React.lazy(
      () => import('../../../statics/help/RunningSuccessfulPromotionAnyGenre')
    ),
  },
  {
    path: '/author-resources/running-successful-romance-promotions',
    component: React.lazy(
      () => import('../../../statics/help/RunningSuccessfulRomancePromotions')
    ),
  },
  {
    path: '/author-resources/running-successful-fantasy-promotions',
    component: React.lazy(
      () => import('../../../statics/help/RunningSuccessfulFantasyPromotions')
    ),
  },
  {
    path: '/author-resources/running-successful-thriller-promotions',
    component: React.lazy(
      () => import('../../../statics/help/RunningSuccessfulThrillerPromotions')
    ),
  },
  {
    path: '/authors/:id/:name',
    component: React.lazy(
      () => import('../../../statics/public-authors/AuthorShow')
    ),
  },
  {
    path: '/books',
    component: React.lazy(
      () => import('../../../statics/public-books/books/BasicBookIndex')
    ),
  },
  {
    path: '/authors',
    component: React.lazy(
      () => import('../../../statics/public-authors/AuthorIndex')
    ),
  },
  {
    path: '/blog/:id/:name',
    component: React.lazy(
      () => import('../../../statics/blog/Show')
    ),
  },
  {
    path: '/blog/categories/:id/:name',
    component: React.lazy(
      () => import('../../../statics/blog-categories/ShowBlogCategory')
    ),
  },
  {
    path: '/blog',
    component: React.lazy(
      () => import('../../../statics/blog/Index')
    ),
  },
  {
    path: '/reader-blog/:id/:name',
    component: React.lazy(
      () => import('../../../statics/reader-blogs/Show')
    ),
  },
  {
    path: '/reader-blog',
    component: React.lazy(
      () => import('../../../statics/reader-blogs/Index')
    ),
  },
  {
    path: '/best-book-promotion-sites',
    component: React.lazy(
      () => import('../../../statics/best-promotion-sites/Index')
    ),
  },
  {
    path: '/best-book-promotion-sites/:id/:name',
    component: React.lazy(
      () => import('../../../statics/best-promotion-sites/Show')
    ),
  },
  {
    path: '/books/:id/:name',
    component: React.lazy(
      () => import('../../../statics/public-books/books/BookShow')
    ),
  },
  {
    path: '/contact',
    component: React.lazy(() => import('../../../statics/Contact')),
  },
  {
    path: '/categories/:id/:name',
    component: React.lazy(
      () => import('../../../statics/public-books/categories/CategoryIndex')
    ),
  },
  {
    path: '/ebook-deals/:freeOnly?',
    component: React.lazy(
      () => import('../../../statics/public-books/books/BookIndex')
    ),
  },
  {
    path: '/pricing',
    component: React.lazy(() => import('../../../statics/pricing/Pricing')),
  },
  {
    path: '/privacy',
    component: React.lazy(() => import('../../../statics/Privacy')),
  },
  {
    path: '/partners-referral-program',
    component: React.lazy(
      () => import('../../../statics/partners/referral-program/ReferralProgram')
    ),
  },
  {
    path: '/partnership-guidelines',
    component: React.lazy(
      () => import('../../../statics/PartnershipGuidelines')
    ),
  },
  {
    path: '/promotion-requirements',
    component: React.lazy(
      () => import('../../../statics/PromotionRequirements')
    ),
  },
  {
    path: '/products/best-e-readers-2023',
    component: React.lazy(
      () => import('../../../statics/products/best-e-readers/BestEReaders2023')
    ),
  },
  {
    path: '/readers/:id/edit',
    component: React.lazy(() => import('../../../statics/readers/EditReader')),
  },
  {
    path: '/subscribers-and-statistics',
    component: React.lazy(() => import('../../../statics/SubscriberCounts')),
  },
  {
    path: '/social-media-icons',
    component: React.lazy(() => import('../../../statics/SocialMediaIcons')),
  },
  {
    path: '/terms-and-conditions',
    component: React.lazy(() => import('../../../statics/Terms')),
  },
  // This homepage route has to be last, or it will
  // be selected as the main route to check.
  {
    path: '/',
    component: React.lazy(() => import('../../../statics/Homepage/Homepage')),
  },
];

const PublicRoutes = function () {
  return (
    <>
      {publicRoutes.map((route: RouteType) => (
        <Route path={route.path} exact key={route.path}>
          <Suspense fallback={<SuspenseLoader />}>
            <AppErrorBoundary>
              {React.createElement(route.component, {})}
            </AppErrorBoundary>
          </Suspense>
        </Route>
      ))}
    </>
  );
};

export default PublicRoutes;
